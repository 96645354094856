<template>
  <div>
    <div class="flex flex-col">
      <div class="w-full bg-action-bar px-2 py-2 clearfix">
        <div class="float-right flex flex-wrap">
          <div class="mx-2 mt-2 lg:mt-0">
            <button class="btn-blue-outline mx-2 mt-3 sm:mt-0" @click.prevent="exportXLS()">
              {{ $t("export") }}
              <BaseIcon icon="file-excel" class="ml-1" />
            </button>
            <router-link v-if="reseller && reseller.special_access.indexOf('create_customer') > -1" class="btn-green" to="/admin/skapa-kund">
              {{ $t("new_customer") }}
              <BaseIcon icon="plus" class="ml-2" />
            </router-link>
          </div>
        </div>
      </div>
      <div class="w-full mt-6">
        <vue-good-table
          styleClass="vgt-table"
          row-style-class="text-sm"
          :columns="tableColumn"
          :rows="customers"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: false,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'tounit'">
              <router-link tag="button" class="btn-blue-outline" :to="'/verksamheter/' + type + '/' + props.row.customer_id + '/' + id">
                {{ $t("activities_table.operation") }}
                <BaseIcon icon="city" class="ml-1" />
              </router-link>
            </span>
            <span v-if="props.column.field == 'toaddon'">
              <router-link tag="button" class="btn-secondary-outline" :to="'/addon-activate/customer/' + props.row.customer_id">
                {{ $t("activities_table.additional_services") }}
                <BaseIcon icon="cube" class="ml-1" />
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'edit'">
              <button v-if="reseller && props.row.seller == reseller._id" class="btn-secondary-outline" @click.prevent="show(props.row)">
                <BaseIcon icon="pencil-alt" />
              </button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>

    <CustomerUpdate :customerInfo="customer_info" :alarmCenters="alarm_centers" :sellers="[]" :reseller="reseller" @updateCustomer="getResellerCustomers()" />
  </div>
</template>

<script>
import CustomerUpdate from "@/components/modal/customer_update";

export default {
  title() {
    return this.$t("page_titles.departments");
  },
  components: {
    CustomerUpdate,
  },
  data() {
    return {
      user: this.$store.state.user,
      type: this.$route.params.type,
      id: this.$route.params.id,
      customers: [],
      alarm_centers: [],
      reseller: null,
      seller: null,
      customer_info: null,
    };
  },
  computed: {
    tableColumn() {
      return [
        { label: this.$t("activities_table.name"), field: "name", sortable: false },
        { label: this.$t("activities_table.org_number"), field: "company_number", sortable: false },
        { label: this.$t("activities_table.email"), field: "email", sortable: false },
        { label: this.$t("activities_table.telephone"), field: "phone", sortable: false },
        { label: this.$t("activities_table.city_town"), field: "city", sortable: false, formatFn: this.getSellerName },
        { label: "", field: "edit", sortable: false },
        { label: "", field: "toaddon", sortable: false },
        { label: "", field: "tounit", sortable: false },
      ];
    },
  },
  methods: {
    getAlarmCentrals() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/alarm-central/`)
        .then((response) => {
          this.alarm_centers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getResellerCustomers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/reseller/${this.id}/customers`)
        .then((response) => {
          this.reseller = response.data.reseller;
          this.customers = response.data.customers;

          this.setPageTitle(`${this.reseller.name} - ${this.$t("top_titles.customer")}`, "customers");
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getUserCustomers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/dashboard/customer/`)
        .then((response) => {
          this.customers = response.data.customers;

          this.setPageTitle(this.$t("top_titles.customer"), "customers");
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    exportXLS() {
      let export_columns = [];
      this.customers.forEach((customer) => {
        export_columns.push({
          [this.$t("customer_export.customer_id")]: customer.customer_id,
          [this.$t("customer_export.name")]: customer.name,
          [this.$t("customer_export.org_number")]: customer.company_number,
          [this.$t("customer_export.email")]: customer.email,
          [this.$t("customer_export.phone")]: customer.phone,
          [this.$t("customer_export.city_town")]: customer.city,
        });
      });

      this.excelExport(export_columns, "kund", "kund_rapport");
    },

    show(customer) {
      this.customer_info = _.cloneDeep(customer);
      this.$modal.show("update-customer");
    },
  },

  created() {
    if (this.type == "reseller") {
      this.getResellerCustomers();
      this.getAlarmCentrals();
    } else if (this.type == "customer") this.getUserCustomers();
  },
  watch: {
    "$i18n.locale"() {
      document.title = this.$t("page_titles.departments");
      this.setPageTitle(this.$t("top_titles.customer"), "customers");
    },
  },
};
</script>
